@import url(https://use.typekit.net/uqy0veg.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
body {
    font-family: 'Montserrat', sans-serif;
    margin: 0px;
    padding: 0px;
    /*background-color: #e1e1e1;*/
}

.container {
    max-width: 1400px;
}

.btn-toolbar>*+* {
    margin-left: .5em;
}

.row.menuRow,
.row.contentRow{
    padding: 10px;
}

.w-200px{
    width: 200px;
}

.form-container .date-picker-wrapper .icon {
    position: absolute;
    right: 25px;
    top: 12px;
}

.form-container .react-datepicker-wrapper{
    width: 100%;
}

.form-container .tag-list {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.form-container .tag-list li {
    margin: 0 5px 0 0;
    padding: 0;
}

.tag-list__remove {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-top: -2px;
    border-radius: 50%;
    background: #aaaaaa url(/static/media/icon-cross.d8fb5595.svg) no-repeat center center;
    background-size: 50% auto;
    text-align: left;
    text-indent: -9999px;
    overflow: hidden;
}
.tag-list__remove:hover,
.tag-list__remove:active {
  background-color: #0996D3;
  cursor: pointer;
}

/*.react-bs-table table td, .react-bs-table table th{
    overflow: none;
    white-space: nowrap;
    text-overflow: none;
}*/

.react-bs-table-container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.react-bs-table-container table td, .react-bs-table-container table th{
    font-size: 0.8rem;
} 
